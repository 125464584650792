<template>
	<div>
		<VueElementLoading
			:active="itemLoading || itemSaving"
			spinner="ring"
			:text="itemsName.one + (itemLoading ? ' загрузка...' : ' сохранение...')"
			:background-color="'rgba(255, 255, 255, .7)'"
		/>

		<div class="view-wrapper item-page-wrapper">
			<div class="page-top-bg-addition" />

			<div class="mcontainer">
				<h1 class="title page-title">{{ pageTitle }}</h1>
				<div class="view-content-card card">
					<div v-if="loadContent" class="form-wrapper card-content">
						<ItemForm
							ref="ItemFormComponent"
							:item-data="itemData"
							:items-name="itemsName"
							@submit="handleSubmitForm"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { navigation, itemPageMixin, initPageDataMixin } from '@/mixins';

export default {
	name: 'ProductPage',

	components: {
		ItemForm: () => import('./ItemForm.vue')
	},
	mixins: [navigation, itemPageMixin, initPageDataMixin],

	computed: {
		...mapState({
			itemLoading: state => state.products.isLoading,
			itemSaving: state => state.products.isSaving,

			itemData: state => state.products.itemData
		}),

		itemsName() {
			return {
				one: 'Товар',
				mult: 'Товары'
			};
		}
	},

	methods: {
		...mapActions({
			// show_overlay: 'show_overlay',
			setup_navbar: 'setup_navbar',

			fetch_item: 'products/fetch_product',
			set_item: 'products/set_product',
			save_item: 'products/save_product'
		}),

		handleSubmitForm(data) {
			const payload = { data: data };

			if (data.mainFile || data.file || (data.files && data.files.length)) {
				payload.withFile = true;
			}
			// payload.data.attribute_options_id = [242, 245];
			this.save_item(payload).then(() => {
				this.changeRoute({ parent: true });
			});
		}
	}
};

/* {
		attribute_groups: [
			{	
				id: 1,
				title_ru: 'эл. параметры',
				...
				attributes: [
					{
						id: 1,
						title_ru: 'мощность',
						...
						attribute_options: [
							{
								id: 1,
								title_ru: '500 Вт'
							}
						]
					},
					{
						id: 1,
						title_ru: 'напряжение',
						...
					}
				]
			},
			{
				id: 2,
				title_ru: '2 группа атрибутов для данного товара',
			}
		]
	} */
</script>
